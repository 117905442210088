export default {
  main: "#1da1f2",
  color: {
    main: "#1da1f2",
    lightMain: "#71c9f8",
    white: "#ffffff",
    background: "#fafafa",
    hoverBackground: "#F7F9F9",
    hoverLightBackground: "#e8f5fe",
    retweetHover: "#00BA7C",
    retweetHoverBackground: "#DEF1EB",
    likeHover: "#F91880",
    likeHoverBackground: "#F7E0EB",
    text: "#0f1419",
    lightText: "#536471",
    border: "#eff3f4",
    icon: "#e7e7e8",
    error: "#ff0000",
    hover: "#1a91da",
    chatBackgroundLeft: "#eff3f4",
    chatBackgroundRight: "#71c9f8",
  },
  fontSize: {
    small: "12px",
    medium: "15px",
    large: "17px",
  },
  padding: {
    small: "16px",
    medium: "30px",
    large: "9999px",
  },
  fontWeight: {
    light: "300",
    regular: "600",
    bold: "900",
  },
};
