import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <g>
      <path d="M19.25 2c-.414 0-.75.336-.75.75v.09c-.695-.245-1.423-.38-2.21-.38-1.453 0-2.876.417-4.25.82-1.334.39-2.592.76-3.83.76-.948 0-1.792-.225-2.58-.688-.234-.135-.52-.136-.753-.002-.233.132-.377.38-.377.65v9.77c0 .267.14.513.37.647 1.025.6 2.117.893 3.34.893 1.453 0 2.876-.417 4.25-.82 1.334-.39 2.592-.76 3.83-.76.802 0 1.527.17 2.21.5v7.02c0 .414.336.75.75.75s.75-.336.75-.75V2.75c0-.414-.336-.75-.75-.75zm-2.96 10.23c-1.453 0-2.876.416-4.25.82-1.333.39-2.592.76-3.83.76-.802 0-1.528-.162-2.21-.49V5.174c.694.245 1.425.366 2.21.366 1.453 0 2.875-.417 4.25-.82 1.333-.39 2.592-.76 3.83-.76.802 0 1.528.16 2.21.49v8.145c-.694-.244-1.425-.366-2.21-.366z"></path>{" "}
    </g>
  </svg>
);
